// CADNPCA-6369 Migrating BI module to static-next
import {Cookies, DOM, Logger, PubSub} from '@cad/static-next-lib';

import DarkMode from '../../ts/services/darkmode';
import Validation from '../../ts/services/validation';
import {tryDetectAdblocker} from './analytics/detectAdblocker';
import * as helper from './analytics/helpers';
import RecoTracking from './analytics/recotracking';
import Tracking2 from '../../ts/services/tracking2';

/**
 * Handles own tracking: brain, Tealium ...
 */
class Analytics {
  /**
   * @constructor
   */
  constructor() {
    this.name_ = 'analytics';
    this.dom_ = DOM;
    this.logger_ = Logger;
    this.pubsub_ = PubSub;

    this.currentLocation_ = {
      href: '',
      msctObj: false,

      /** @type {string[]|boolean} */
      brainObj: false,

      untreatedBrainZone: false,
      layoutClass: undefined,
      deviceClass: undefined,
      deviceClient: undefined,
      ff: undefined
    };
    this.videoAutoplay_ = undefined;
    this.isEvent_ = false;
    this.hashActive_ = false;
    this.adblockerDetected_ = false;
    this.iframeTrackingStarted_ = false; // CADNPCA-6325
    this.bypassFirstPixelPatterns_ = ['wahlomat-']; // if clickname matches pattern, bypass first request
    this.bypassFirstPixelReqs_ = []; // buffer for clicknames CADNPCA-7519
    this.tcfLayerShown_ = false;
    this.permissionGranted_ = false;
    this.paramsNeedConsent_ = [];
    this.alreadyGotDeviceClass_ = false;
  }

  /**
   * For unit-test
   * @param {string} href
   */
  set currentLocationHref(href) {
    this.currentLocation_.href = href;
  }

  /**
   * Initialization
   *
   * @param {Object=} locationParams Initial values for "currentLocation_"
   * @param {boolean=} permission
   */
  init(locationParams = {}, permission = false) {
    this.currentLocation_.href = locationParams.href || window.location.href;
    this.currentLocation_.deviceClient = (window.ui && window.ui.pageinfo) ?
      window.ui.pageinfo.deviceclient : 'browser';

    try {
      if (window.sessionStorage.getItem('_rfcp_')) {
        this.tcfLayerShown_ = true;
      }
    } catch (e) {
      Logger.logUnknownError(e, 'analytics');
    }

    this.permissionGranted_ = permission;
    // params that should only be sent to tracker if users give their consents
    // CADNPCA-6986
    this.paramsNeedConsent_ = ['hid', 'loginstate', 'lvts', 'sample', 'screenres', 'screenresolution', 'segment',
      'session', 'var', 'viewportheight', 'viewportwidth', 'viewres'];

    RecoTracking.init();

    this.adblockerDetected_ = tryDetectAdblocker();

    Tracking2.updateUtagData(this.adblockerDetected_);

    this.readTraceTracking();

    this.attachAnalyticsListeners();

    this.addPartnerTrackingPixel();

    // expose potec.countWAPI
    if (!window.potec) {
      window.potec = {};
    }
    if (typeof window.potec.countWAPI !== 'function') {
      window.potec.countWAPI = this.countWAPI;
    }
  }


  /**
   * Read trace data from cookie
   */
  readTraceTracking() {
    let params;
    try {
      // use localStorage CADNPCA-7779
      // remove existing cookie
      const traceCookie = Cookies.getItem('trace');
      if (traceCookie) {
        Cookies.removeItem('trace');
      }
      let traceData = localStorage.getItem('trace');
      if (traceData) {
        traceData = JSON.parse(traceData);
        if (traceData['location'] === helper.unifyUrl(this.currentLocation_.href)) {
          params = traceData['brainZone'].split('.');
          this.currentLocation_.brainObj = params;
          this.currentLocation_.untreatedBrainZone = traceData['brainZone'];
          localStorage.removeItem('trace');
        }
      }
    } catch (e) {
      this.log(e.toString());
    }
  }

  /**
   * Attach Listeners for Tracking-Event-Capturing
   */
  attachAnalyticsListeners() {
    // (temp) general event tracking for static-next
    this.subscribe('tracker:sendEventPixel', obj => {
      this.isEvent_ = true;
      this.controlTracking(obj, false);
    });
    // general PI tracking
    this.subscribe('staticnext:sendPIPixel', obj => {
      this.controlTracking(obj, false);
    });

    // starpreview open or close event
    this.subscribe('starpreview:openOrCloseEvent', obj => {
      this.isEvent_ = true;
      this.controlTracking(obj, false);
    });

    // starpreview text link 1
    this.subscribe('starpreview:textlink1', obj => {
      this.setTraceTracking(obj.targetUrl, obj.brainData);
    });

    // (fake) video event tracking
    this.subscribe('analytics:trackVideoEvent', ev => {
      this.log(`Video: ${ev}`);
    });

    this.subscribe('video-slider:setEventPixel', data => {
      this.isEvent_ = true;
      this.controlTracking(data, false);
    });

    this.subscribe('video-slideshow:setPixel', jsonData => {
      this.controlTracking(this.readTriggerTracking(jsonData), false);
    });

    this.subscribe('video-slideshow:setEventPixel', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
    });

    this.subscribe('video-slider:brain-tracking', jsonData => {
      this.controlTracking(jsonData, false);
    });

    // for quiz
    this.subscribe('quiz:setPixel', jsonData => {
      this.controlTracking(jsonData, false);
      Tracking2.callTealiumView({newContentType: 'quiz.detail'});
    });

    // for slideshow
    this.subscribe('slideshow:setPixel', jsonData => {
      this.controlTracking(this.readTriggerTracking(jsonData), false);
      Tracking2.callTealiumView({newContentType: 'slideshow.detail'});
    });

    this.subscribe('slideshow:arrowClicked', data => {
      this.isEvent_ = true;
      this.controlTracking(data, false);
    });

    // for voting
    this.subscribe('votingtool:setPixel', jsonData => {
      this.controlTracking(this.readTriggerTracking(jsonData), false);
      Tracking2.callTealiumView();
    });

    // for comments
    this.subscribe('comments:setPixel', jsonData => {
      this.controlTracking(jsonData, false);
      Tracking2.callTealiumView({newContentType: 'article.comments'});
    });
    this.subscribe('comments:setEvent', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
      Tracking2.callTealiumView();
    });
    this.subscribe('comments:loginDialogShow', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
      Tracking2.callTealiumView({newContentName: 'login-layer', newPageType: 'layer'});
    });

    // for videos
    this.subscribe('video:setPixel', jsonData => {
      if (jsonData['autoPlay']) {
        this.videoAutoplay_ = jsonData['autoplay'];
        delete jsonData['autoplay'];
      }
      this.controlTracking(this.readTriggerTracking(jsonData), false);
    });

    // for magnifier
    this.subscribe('magnifier:setPixel', jsonData => {
      this.controlTracking(jsonData, false);
      Tracking2.callTealiumView({newContentType: 'article.fullscreen-image'});
    });

    // for regional content when select is opened
    this.subscribe('region:open-select', data => {
      this.isEvent_ = true;
      this.controlTracking(data, false);
    });

    // for figcaptions
    this.subscribe('figcaption:setPixel', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
      Tracking2.callTealiumView();
    });

    // for social sharing
    this.subscribe('social:share', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
    });

    // for header multisearch
    this.subscribe('header:multisearch', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
    });

    // headerBidding pixel tracking
    this.subscribe('createHeaderBiddingPixel', url => {
      (new Image()).src = url;
    });

    // feedback
    this.subscribe('content:feedback', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
    });

    // article end reached
    this.subscribe('content:articleReadProgress', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
    });

    // Infinitescroll scrolling progress (i.e. start, 50%, end)
    this.subscribe('content:infinitescrollScrolling', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
    });

    // contentvoting
    this.subscribe('content:voting', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
    });

    // rating
    this.subscribe('article:rating', jsonData => {
      this.isEvent_ = true;
      this.controlTracking(jsonData, false);
    });

    // Add brain-zone
    this.subscribe('general:newContent', elem => {
      this.appendBrainZone(elem);
    });

    this.subscribe('navigation:ready', naviNode => {
      this.appendBrainZone(naviNode);
    });

    this.subscribe('slideshow:ready', element => {
      this.appendBrainZone(element);
    });

    this.subscribe('region:optionClick', obj => {
      this.setTraceTracking(obj['targetUrl'], obj['brainData']);
    });

    // horoscope
    this.subscribe('horoscope:brainzone', obj => {
      const lnk = obj.getElementsByTagName('a')[0];
      const br = lnk.getAttribute('data-brain-zone');
      this.setTraceTracking(lnk.href, br);
      location.href = lnk.href;
    });

    // dialog box
    this.subscribe('track:dialogShow', obj => {
      this.controlTracking(obj, false);
      Tracking2.callTealiumView();
    });

    this.subscribe('track:welcomebackDialogShow', obj => {
      this.controlTracking(obj, false);
      Tracking2.callTealiumView({newContentName: 'welcome-layer', newPageType: 'layer', newAgof: '735'});
    });

    this.subscribe('track:dialogCloseNormalPi', obj => {
      this.controlTracking(this.readTriggerTracking(obj), false);
    });

    // emoji-feedback
    this.subscribe('track:emojifeedback', obj => {
      this.isEvent_ = true;
      this.controlTracking(obj, false);
    });

    // feedback category selected
    this.subscribe('feedback:form', obj => {
      this.isEvent_ = true;
      this.controlTracking(obj, false);
    });

    // Welcome back layer
    this.subscribe('welcomeback:brainzone', node => {
      this.appendBrainZone(node);
    });

    this.subscribe('relatedvideo:setclick', data => {
      this.setTraceTracking(data['destination'], data['brainData']);
    });

    // CADNPCA-8462
    this.subscribe('navigation:brainZone', data => {
      console.log('click from navi ', data);
      this.setTraceTracking(data['destination'], data['brainData']);
    });

    // Actual tracking activities on page load!
    this.subscribe('ad:gotDeviceClass', adSrv => {
      if (Object.keys(adSrv).length < 1) return; // in case params are not yet provided by AdService
      if (this.alreadyGotDeviceClass_) return; // don't exec twice

      this.currentLocation_.ff = adSrv['ff'];
      this.currentLocation_.layoutClass = adSrv['layoutclass'];
      this.currentLocation_.deviceClass = adSrv['deviceclass'];

      this.appendBrainZone(); // scan whole page(!)

      if (document.querySelector('[data-mod-name="inboxnews"]')) {
        this.controlTracking({
          'clickname': 'open',
          'referrer': 'undef',
          'contentmode': DarkMode.isActivated() ? 'darkmode-on': 'darkmode-off'
        }, false);
      } else if (document.querySelector('[data-mod-name="imagegallery"]')) {
        const slideCount = /** @type HTMLElement */ (document.querySelector('[data-mod-name="imagegallery"]')).dataset.slideTotal;
        this.controlTracking({
          'clickname': 'slideshow_right',
          'slidenumber': '0/' + slideCount,
          'contentmode': DarkMode.isActivated() ? 'darkmode-on': 'darkmode-off'
        }, false);
      } else {
        this.controlTracking({
          'contentmode': DarkMode.isActivated() ? 'darkmode-on': 'darkmode-off'
        }, true);
        this.clickOuts();
        this.trackTeaserImpressions();
        this.optOutTransfer();
      }

      this.alreadyGotDeviceClass_ = true;
    });

    this.subscribe('inboxnews:optOut', data => {
      this.isEvent_ = true;
      this.controlTracking(data, false);
    });

    this.subscribe('externalembed:optin', data => {
      this.isEvent_ = true;
      this.controlTracking(data, false);
    });

    this.subscribe('inapp:topnav', data => {
      this.isEvent_ = true;
      this.controlTracking(data, false);
    });

    this.subscribe('appbanner:event', data => {
      this.isEvent_ = true;
      this.controlTracking(data, false);
    });

    // Whitelabel / iframes
    this.attachWhitelabelListener();
  }

  /**
   * External partner tracking
   */
  addPartnerTrackingPixel() {
    try {
      if (window.ui.partnerTrackingPixel) {
        const urls = window.ui.partnerTrackingPixel;
        urls.forEach(url => {
          (new Image()).src = url;
        });
      }
    } catch (e) {
      this.log(e);
    }
  }

  /**
   * Handles firing tracking events
   * msct tracking only occurs when a #msct hash is submitted or in "bestprice" and advertorial page
   *
   * @param {Object} trackingData Additional tracking data
   * @param {boolean} linksDereferred Indicates of the object for dereferrer links should be created
   */
  controlTracking(trackingData, linksDereferred) {
    if (!trackingData) {
      trackingData = {};
    }

    const controlVars = this.getControllVars();

    // if fireBrain is set to false, buildBrainUrl() will be called in msctInit() for homepageHashes (to prevent double pixel)
    if (controlVars.fireBrain) {
      if (this.checkBypassFirstPixel(trackingData.clickname)) {
        return;
      }
      this.buildAndFireBrainUrl(trackingData, linksDereferred);
    }

    // HASH AVAILABLE: initialize msctConversion or BRAIN tracking
    if (controlVars.retVal) {
      this.initMsct(controlVars.retVal, controlVars.removeStr, trackingData);
    }
  }

  /**
   * Checks and sets necessary control variables for "controlTracking" method
   * detects if there is a hash in URL
   * detects if advertorial is present
   *
   * @return {Object}
   */
  getControllVars() {
    let retVal = '';
    let removeStr = '';
    const hashPosition = this.currentLocation_.href.indexOf('#');
    const msctHash = this.currentLocation_.href.slice(hashPosition, (hashPosition + 5));
    let fireBrain = true;

    if (hashPosition !== -1 || window.homepagehash) {
      this.hashActive_ = true;
      if (msctHash === '#msct') {
        retVal = 'msct';
        removeStr = 'msct_';
      } else {
        let hashOnly = this.currentLocation_.href.slice(hashPosition, this.currentLocation_.href.length);
        let checkHomepageHash = hashOnly.indexOf('#.');
        // Special treatment for tv program:
        // The partner has an angular app running at tv program which uses the hash for routing.
        // Therefore they store the homepage tracking hash in window.homepagehash before routing within their webapp.
        if (window.homepagehash) {
          this.log('Got "window.homepagehash" for tv program. Overwriting variables.');
          hashOnly = window.homepagehash;
          checkHomepageHash = 0;
          delete window.homepagehash;

          // Prevent hash-removal
          this.hashActive_ = true;
        }

        if (checkHomepageHash === 0) {
          // Inboxad and homepage have about the same URL hash schema.
          // For separating homepage vs inboxad we check if the second parameter equals 'inboxad'.
          retVal = helper.getParameter('.', hashOnly, this.currentLocation_.href)[2] === 'inbox' ?
            'inbox' : 'homepage';
          fireBrain = false;
          removeStr = hashOnly;
        }
      }
    }

    return {
      retVal: retVal,
      fireBrain: fireBrain,
      removeStr: removeStr
    };
  }

  /**
   * Builds and fires the complete brain-URL.
   *
   * @param {Object} trackingData
   * @param {boolean} linksDereferred
   */
  buildAndFireBrainUrl(trackingData, linksDereferred) {
    if (!window.ui.brain) return;

    let piUrl = window.ui.brain.piUrl;

    if (this.isEvent_) {
      piUrl = window.ui.brain.eventUrl;
      this.isEvent_ = false; // TODO
    }

    const piObj = {
      piUrl: piUrl,
      screenres: window.screen.width + 'x' + window.screen.height,
      viewres: document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight,
      layoutclass: this.currentLocation_.layoutClass,
      deviceclass: this.currentLocation_.deviceClass,
      deviceclient: this.currentLocation_.deviceClient,
      click1: this.currentLocation_.brainObj[1],
      click2: this.currentLocation_.brainObj[2],
      click3: this.currentLocation_.brainObj[3],
      click4: this.currentLocation_.brainObj[4],
      clickname: trackingData.clickname || 'undefined',
      pageVisibility: document.visibilityState, // [CADNPCA-9242]
      ts: Date.now(),
      random: parseInt((Math.random() * 100000).toString(), 10),
      adblock: this.adblockerDetected_ ? '1' : '0',
      title: encodeURIComponent(document.title),
      tcflayer: this.tcfLayerShown_ ? 1 : 0
    };

    // if backend does NOT render the ui.brain.piUrl (with a type), we add the default type to our object
    if (!piUrl.includes('type=')) {
      piObj.type = 'view';
    }

    // the original referrer saved before the user was redirected to the consent page
    let originalReferrer = sessionStorage.getItem('originalReferrer');

    if (originalReferrer) {
      // since the 'originalReferrer' is set in the inline-redirect script, we must delete it here
      // otherwise the same referrer will be taken for all subsequent page visits
      sessionStorage.removeItem('originalReferrer');
    } else {
      originalReferrer = document.referrer;
    }

    const piObjExt = {
      pageurl: encodeURIComponent(this.currentLocation_.href),
      referrer: encodeURIComponent(originalReferrer)
    };

    const builtUrl = this.buildTrackingUrl(piObj, piObjExt, trackingData, linksDereferred);

    if (!this.isEvent_) {
      try {
        const builtUrlObj = new URL(builtUrl);
        const searchParams = new URLSearchParams(builtUrlObj.search);
        const name = searchParams.get('name');
        if (name.includes('.pi.')) {
          RecoTracking.increaseTrackingCounter('piCount');
        }
      } catch (e) {
        Logger.logUnknownError(e, 'analyticsReco');
      }
    }

    this.pubsub_.publish('analyticsReco:clicksReady', this.currentLocation_.brainObj);

    // finally fire tracking request!
    // using sendbeacon for videoplayer pixels only (for now)
    if (trackingData.videoaction) {
      window.navigator.sendBeacon(builtUrl);
    } else {
      (document.createElement('img')).src = builtUrl;
    }

    // For selenium test
    if (Array.isArray(window.ui.brain.pisend)) {
      if (window.ui.brain.pisend.indexOf(builtUrl) < 0) {
        window.ui.brain.pisend.push(builtUrl);
      }
    } else {
      window.ui.brain.pisend = [builtUrl];
    }
  }

  /**
   * Builds the URL for tracking
   *
   * @param {Object} piData
   * @param {Object} piDataExt
   * @param {Object} piReplace
   * @param {boolean} linksDereferred
   * @return {string}
   */
  buildTrackingUrl(piData, piDataExt, piReplace, linksDereferred) {
    let finalUrl = '';
    if (piData['type'] && piReplace['piUrl'] && piReplace['piUrl'].includes('type=')) {
      delete piReplace['type'];
      delete piData['type'];
    }

    let piDataMerged = Object.assign(piData, piReplace);
    const piDataExtMerged = Object.assign(piDataExt, piReplace);
    piDataMerged = Object.assign(piDataMerged, piDataExtMerged);

    finalUrl = piDataMerged['piUrl'];
    delete piDataMerged['piUrl']; // for unified loop operation, see below

    // remove params that need consent if no permission
    piDataMerged = this.onlyPermittedParams(piDataMerged);

    for (const prop in piDataMerged) {
      if (Validation.hasProperty(piDataMerged, prop)) {
        // clean up old tracking key/value format like "key: '&key=value'"
        const val = String(piDataMerged[prop]).replace(`&${prop}=`, '');
        finalUrl += `&${prop}=${val}`;
      }
    }

    this.generateDerefererLink(piData, piDataExt, linksDereferred);

    return finalUrl;
  }

  /**
   * generates window.ui.brain.dereferer object for target blank links (executed once)
   *
   * @param {Object} piData
   * @param {Object} piDataExt
   * @param {boolean} linksDereferred
   */
  generateDerefererLink(piData, piDataExt, linksDereferred) {
    let derefererUrlExt = '';

    if (!linksDereferred) {
      return;
    }

    let piDataMerged = Object.assign(piData, piDataExt);

    if (window.ui.brain) {
      const curBrainObj = this.currentLocation_.brainObj;
      piDataMerged['piUrl'] = '';

      [1, 2, 3, 4].forEach(i => {
        piDataMerged['click' + i.toString()] = (curBrainObj[i] && curBrainObj[i] !== '') ?
          `&click${i}=${curBrainObj[i]}` : '';
      });

      delete piDataMerged['type'];
      piDataMerged = this.onlyPermittedParams(piDataMerged);

      for (const prop in piDataMerged) {
        if (Validation.hasProperty(piDataMerged, prop)) {
          derefererUrlExt += `&${prop}=${piDataMerged[prop]}`;
        }
      }

      window.ui.brain.dereferer = derefererUrlExt;
    }
  }

  /**
   * Removes params regarding permission
   * @param {Object} params
   * @return {Object}
   */
  onlyPermittedParams(params) {
    if (!this.permissionGranted_) {
      this.paramsNeedConsent_.forEach(p => {
        delete params[p];
      });
    }
    return params;
  }

  /**
   * Init msct
   *
   * @param {string} retVal
   * @param {string} removeStr
   * @param {Object} trackingData
   */
  initMsct(retVal, removeStr, trackingData) {
    const msctControlVars = this.getMsctControlVars(retVal, removeStr);
    try {
      if (msctControlVars.fireBrain !== false) {
        this.currentLocation_.brainObj = msctControlVars.fireBrain;
        this.buildAndFireBrainUrl(trackingData, false);
      }

      if (msctControlVars.removeHash && this.hashActive_) {
        this.hashActive_ = false;
        this.currentLocation_.href = helper.removeHashFromUrl();
      }
    } catch (e) {
      this.log(e);
    }
  }

  /**
   * Get control variables for msct
   *
   * @param {string} retVal
   * @param {string} removeStr
   * @return {any}
   */
  getMsctControlVars(retVal, removeStr) {
    let removeHash = false;
    let fireConversion = false;

    /** @type {boolean|any[]} */
    let fireBrain = false;

    const setInitialOnloadParams = obj => {
      if (!Validation.hasProperty(obj, 'mtype')) {
        obj['mtype'] = 'wsite';
      }
      obj['pageid'] = 0;
      return obj;
    };

    switch (retVal) {
      case 'msct':
      {
        const tempConversion = helper.separateParameters(helper.getParameter(';', undefined, this.currentLocation_.href), removeStr);
        fireConversion = setInitialOnloadParams(tempConversion);
        removeHash = true;
        break;
      }
      case 'homepage':
      case 'inbox':
      {
        const hashParams = helper.getParameter('.', removeStr);
        fireBrain = hashParams;
        removeHash = true;
        break;
      }
      case 'advertorial':
        fireConversion = setInitialOnloadParams({});
        break;
    }

    return {
      fireConversion,
      fireBrain,
      removeHash
    };
  }

  /**
   * Similar Function like readTraceTracking. Difference is that this function is used for
   * "onside" click-tracking in case of overlay modules like slideshow
   * Check if there is a trigger via embedded element
   * If yes adjust the brain(click1-4) tracking, see CADNPCA-1520
   *
   * @param {Object} trackingData
   * @return {Object}
   */
  readTriggerTracking(trackingData) {
    let splitParams;
    try {
      this.currentLocation_.brainObj = false;
      if (window.ui.brain && window.ui.brain.trigger) {
        this.log('brain.trigger: ' + window.ui.brain.trigger);
        splitParams = window.ui.brain.trigger.split('.');
        this.currentLocation_.brainObj = splitParams;
        delete window.ui.brain.trigger;
        [1, 2, 3, 4].forEach(i => {
          delete trackingData[`click${i.toString()}`];
        });
        this.log('readTriggerTracking: ' + JSON.stringify(trackingData));
      }
    } catch (e) {
      this.log(e);
    }

    return trackingData;
  }

  /**
   * Attach brainZone handler
   *
   * @param {Element=} source Root element
   */
  appendBrainZone(source) {
    const rootElem = source || document;
    const bzElems = rootElem.querySelectorAll('a[data-brain-zone]');
    const isNavi = source ? source.classList.contains('innernav') : false;

    const setBrainListener = node => {
      node.addEventListener('click', async ev => {
        // handles brainZone clicks
        const lnk = ev.currentTarget;
        let brainData = lnk.getAttribute('data-brain-zone');
        const dest = lnk.getAttribute('href');

        await window.yieldToMain();
        this.setTraceTracking(dest, brainData);
      });
      node.setAttribute('data-brained', 'true');
    };

    Array.from(bzElems).forEach(elem => {
      if (isNavi) {
        setBrainListener(elem);
      } else if ((!elem.hasAttribute('data-brained')) && (!elem.hasAttribute('data-co'))) {
        setBrainListener(elem);
      }
    });
  }

  /**
   * Click-tracking - set the TraceTracking information
   *
   * @param {string} destination
   * @param {string} brainData
   */
  setTraceTracking(destination, brainData) {
    try {
      const brainStore = JSON.stringify({
        location: helper.unifyUrl(destination),
        brainZone: brainData
      });

      localStorage.setItem('trace', brainStore);
    } catch (e) {
      this.log(e);
    }
  }

  /**
   * Handling Click-Outs
   * if a <a> has "data-co", it is a "clickout" link (out of the magazine)
   * There are two different kinds of Click-Outs:
   * - via Brain dereferer
   *   the links are already build as external links, we only attach necessary tracking parameters
   * - via Microstrategy
   *   Handling is done via Microstrategy
   *
   *   @return {Boolean}
   */
  clickOuts() {
    const coLinks = /** @type NodeListOf<HTMLAnchorElement> */ (document.querySelectorAll('a[data-co]'));
    let brainDeref = window.ui.brain ? window.ui.brain.dereferer : '';

    if (!brainDeref) {
      return false; // because we dob't have try/catch
    }

    const filteredClickParams = brainDeref.split('&').filter(p => {
      return !(p.indexOf('click1') > -1 || p.indexOf('click2') > -1 ||
        p.indexOf('click3') > -1 || p.indexOf('click4') > -1);
    });
    brainDeref = filteredClickParams.join('&');
    Array.from(coLinks).forEach(l => {
      if (l.hasAttribute('data-msctd')) {
        return;
      }
      const coType = l.getAttribute('data-co');
      if (coType === 'brain') {
        l.href += brainDeref;
      }

      l.setAttribute('data-msctd', 'true');
    });
  }

  /**
   * CADNPCA-4316
   */
  trackTeaserImpressions() {
    if (window.ui.brain) {
      let tiUrl = window.ui.brain.teaserImpressions;
      if (tiUrl) {
        tiUrl += '&layoutclass=' + this.currentLocation_.layoutClass;
        tiUrl += '&deviceclass=' + this.currentLocation_.deviceClass;
        tiUrl += '&deviceclient=' + this.currentLocation_.deviceClient;
        tiUrl += '&ts=' + (new Date()).getTime().toString();
        (new Image()).src = tiUrl;
      }
    }
  }

  /**
   * Attach Listener for Whitelabel/Iframe Partners to enable cross-iframe communication via postMessage()
   */
  attachWhitelabelListener() {
    // for Civey widget
    window.addEventListener('message', ev => {
      if (ev.origin !== 'https://widget.civey.com') {
        return;
      }
      if (ev.data.type === 'CIVEY_WIDGET_LOADED' || ev.data.type === 'CIVEY_WIDGET_VOTE') {
        window.postMessage({
          method: 'countWAPI',
          click2: 'civeypoll',
          eventname: false
        }, '*');
        // ad-refresh
        window.postMessage({
          method: 'adRefresh',
          rect: true
        }, '*');
      }
    });

    // Real listener for postMessage()
    window.addEventListener('message', e => {
      if (e.data.method === 'countWAPI') {
        const basicData = {
          'referrer': 'undefined',
          'click1': 'undefined',
          'click2': 'undefined',
          'click3': 'undefined',
          'click4': 'undefined',
          'clickname': 'undefined'
        };

        if (e.data.eventname !== false) {
          this.isEvent_ = true;
        }
        delete e.data.method;
        delete e.data.eventname;
        delete e.data.trigger;

        const extData = Object.assign(basicData, e.data);

        this.controlTracking(extData, false);
        Tracking2.callTealiumView();
      }
    }, false);
  }

  /**
   * Tracking - for whitelabel partners
   * Whitelabel partners have to call this method like this: "potec.countWAPI()"
   *
   * @param {string} optClickname
   * @param {boolean} optEvent
   */
  countWAPI(optClickname, optEvent) {
    try {
      // !!! in case of ANY changes - please check the same function definition in iframeResizer.contentWindow.min.js
      const optParams = {
        method: 'countWAPI',
        clickname: optClickname ? optClickname.toString() : 'undefined',
        eventname: Boolean(optEvent)
      };
      window.postMessage(optParams, '*');
    } catch (e) {
      this.log(e);
    }
  }

  /**
   * CADNPCA-6080
   */
  optOutTransfer() {
    const uiBrain = window.ui.brain;
    if (uiBrain) {
      const optOutUrl = uiBrain.optOutTransferUrl;
      if (optOutUrl) {
        (new Image()).src = optOutUrl;
      }
    }
  }

  /**
   * Check if first req should be ignored
   * @param {string} clickname
   * @return {boolean}
   */
  checkBypassFirstPixel(clickname) {
    let retVal = false;
    if (!clickname) {
      return retVal;
    }
    this.bypassFirstPixelPatterns_.forEach(p => {
      if (clickname.indexOf(p) >= 0) {
        // contain pattern, check if already bypassed
        if (this.bypassFirstPixelReqs_.indexOf(clickname) >= 0) {
          // already bypassed, no more
          retVal = false;
        } else {
          // add to bypass buffer
          this.bypassFirstPixelReqs_.push(clickname);
          retVal = true;
        }
      } else {
        // no match, not bypassing
        retVal = false;
      }
    });
    return retVal;
  }

  /**
   * Shortcut: log things
   * @param {string} msg
   */
  log(msg) {
    this.logger_.log(this.name_, msg);
  }

  /**
   * Shortcut: subsub
   * @param {string} topic
   * @param {Function} callback
   */
  subscribe(topic, callback) {
    this.pubsub_.subscribe(topic, /** @type {(data: any) => void} */(callback));
  }
}

export default new Analytics();
