import {html} from 'lit-html';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';

interface TeaserData {
  html: string;
}

/**
 * Renders the recommended teaser section if there are recommended teasers.
 */
function recommendedSection(recommendedTeasers: TeaserData[]) {
  if (recommendedTeasers && recommendedTeasers.length > 0) {
    return html`${recommendedTeasers.map(r => html`${unsafeHTML(r.html)}`)}`;
  }
}

export function wblayer(mandant: string, recommended: TeaserData[]) {
  return html`<div data-mod-name="welcomeback">
    <div class="wbcontent__top">
      <div class="welcome">
        ${unsafeHTML(mandant)}<span class="greetings">Das könnte Sie auch interessieren</span>
      </div>
    </div>

    <div class="wbcontent">
      <div class="wbcontent__teasers">
        <div class="teaser-list-horizontal">
          ${recommendedSection(recommended)}
        </div>
      </div>

      <div class="wbcontent__hpad">
        <div class="co-container-container">
          <div class="co-container co-wbhalfpage js-co-dynamic" data-service-param-tagid="rectangle_layer"></div>
        </div>
      </div>
    </div>

    <!--hide further popups-->
    <a href="#" class="hint js-shownomore">Nicht mehr anzeigen</a>
  </div>`;
}
