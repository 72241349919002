import {html, render} from 'lit-html';
import {DOM, Logger, PubSub} from '@cad/static-next-lib';
import Tracking2 from './tracking2';

const fullscreenTemplate = (copyrightText: string, captionText: string, imageUrl: string) => html`
  <div class="imageFullScreen">
    <a href="#" class="closeOverlay">
      <span class="icon icon-close"></span>
    </a>
    <figure class="imgContainer bgimg" style="background-image: url('${imageUrl}');">
      <figcaption class="figcaption">
        <span class="icon-subtext icon"></span>
        <div class="caption">
          <span class="description">
            <span class="copyright">
              ${copyrightText}
            </span>
            ${captionText}
          </span>
        </div>
      </figcaption>
    </figure>
  </div>`;

type ImgInfo = {
  copyright: string,
  description: string,
  pictureContent: string,
  overlayInfo: OverlayInfo
}

type OverlayInfo = {
  overlayElem: HTMLElement,
  overlayContentSelector: string,
  overlayContentWrapperSelector: string
}

/** Service to display an overlay element and fill it with the desired markup */
class Magnifier {
  private lineHeight: number;

  constructor() {
    this.lineHeight = 25;
  }

  init() {
    try {
      PubSub.subscribe('overlay:showBiggerImage', obj => {
        const imgInfo = obj as ImgInfo;
        const getCopyright = imgInfo.copyright;
        const getDescription = imgInfo.description;
        const imageUrl = imgInfo.pictureContent;
        const fragment = DOM.fromHtml('');

        render(fullscreenTemplate(getCopyright, getDescription, imageUrl), fragment); // maybe wrong idk
        const overlayContainer = document.getElementsByClassName('ocw')[0];
        overlayContainer.appendChild(fragment);

        const closeButton = document.querySelector('a.closeOverlay'); // maybe wrong idk
        const layerContainer = imgInfo.overlayInfo.overlayElem;

        if (closeButton && layerContainer) {
          closeButton.addEventListener('click', e => {
            e.preventDefault();
            layerContainer.parentNode?.removeChild(layerContainer);
            this.sendCloseEvent();
          });
        }

        document.addEventListener('keyup', e => {
          if (e.key === 'Escape') {
            layerContainer.parentNode?.removeChild(layerContainer);
            this.sendCloseEvent();
          }
        });

        this.sendOpenEvent();

        const magnifierNode = document.querySelector('.imageFullScreen');
        const figcaption = magnifierNode?.querySelector('figcaption'); // maybe wrong idk

        if (figcaption) {
          this.configureCaptionStyling(figcaption, false);

          if (figcaption.classList.contains('is-extendable')) {
            // bind caption click event, if caption has enough content
            figcaption.addEventListener('click', function(evt) {
              evt.stopPropagation();

              // const captionIsOpen = goog.dom.classlist.toggle(figcaption, 'opened')
              if (figcaption.classList.contains('opened')) {
                figcaption.classList.remove('opened');
              } else {
                figcaption.classList.add('opened');
              }
              const caption = figcaption.querySelector<HTMLElement>('.caption');

              try {
                if (caption) {
                  if (figcaption.classList.contains('opened')) {
                    localStorage.setItem('descriptionState', 'opened');
                    caption.style.height = caption.dataset.height + 'px';
                  } else {
                    localStorage.setItem('descriptionState', 'false');
                    caption.style.height = '';
                  }
                }
              } catch (e) {
                Logger.logUnknownError(e, 'Magnifier');
              }
            });
          }
        }
      });
    } catch (e) {
      Logger.logUnknownError(e, '[magnifier]');
      PubSub.publish('sentry:log', {error: e});
    }
  }

  /** Hide caption opening arrow if caption is not long enough and calculate extendable height */
  configureCaptionStyling(figcaption: Element, captionState: string|boolean) {
    const description = figcaption.getElementsByClassName('description')[0] as HTMLElement;
    const caption = figcaption.getElementsByClassName('caption')[0] as HTMLElement;

    let height = 0;

    // calculate height of caption by temporally extending decription
    description.style.whiteSpace = 'normal';
    height = description.offsetHeight;
    description.style.whiteSpace = '';

    // add/remove class in caption container
    if (captionState === 'opened') {
      figcaption.classList.add('opened');
      // setting height is necessary for animation
      window.setTimeout(() => {
        caption.style.height = height + 'px';
      }, 500);
    } else {
      figcaption.classList.remove('opened');
    }

    // if copyright is empty, hide it
    const copyright = figcaption.getElementsByClassName('copyright')[0] as HTMLElement;
    if (copyright.textContent) {
      if ((copyright.textContent.length - 2) === 0) {
        copyright.style.display = 'none';
      }
    }

    // if text is extendable, show arrow and make it clickable
    if ((description.offsetWidth < description.scrollWidth) || (description.offsetHeight > (this.lineHeight * 1.5))) {
      figcaption.classList.add('is-extendable');
    }

    // set data-height of caption
    caption.dataset.height = height.toString();
  }

  sendOpenEvent() {
    PubSub.publish('magnifier:setPixel', {
      'referrer': '&referrer=undefined',
      'click1': '&click1=' + window.ui['pageinfo']['coremid'],
      'click2': '&click2=magnifier',
      'click3': '&click3=open',
      'click4': '&click4=1',
      'clickname': '&clickname=magnifier_open'
    });

    Tracking2.callTealiumLink({
      businessEventType: 'userAction',
      componentPath: 'article__featured-image',
      componentResultState: 'enlarged',
      elementTagName: 'div',
      eventType: 'mousedown'
    });
  }

  sendCloseEvent() {
    PubSub.publish('overlayDestroyed', {
      'resetAdConfig': false,
      'moduleName': 'magnifier',
      'optTrackingName': '_close'
    });

    Tracking2.callTealiumLink({
      businessEventType: 'userAction',
      componentPath: 'article__featured-image_close',
      componentResultState: 'minimized',
      elementTagName: 'span',
      eventType: 'mousedown'
    });
  }
}

export default new Magnifier();
