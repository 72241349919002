/**
 * Ensure URL does't contain trailing slash
 * @param {string} url
 * @return {string} url without trailing slash
 */
function unifyUrl(url: string): string {
  return (url.substr(-1) === '/') ? url.substr(0, url.length - 1) : url;
}

/**
 * Get parameters from hash (homepage/inbox) separated by defined separator.
 * Parses optional searchString, falls back to currentLocation.href.
 */
function getParameter(separator: string, searchString: string, href = ''): string[] {
  let splitParams: string[] = [];
  const searchStr = searchString ? searchString : href;

  const locationSplit = searchStr.split('#');
  if (locationSplit.length > 1) {
    splitParams = locationSplit[1].split(separator);
  }

  return splitParams;
}

/** Separates parameters */
function separateParameters(params: string[], removeStr: string): Record<string, string> {
  const obj: Record<string, string> = {};
  let oKey;
  let oVal;
  params.forEach(p => {
    oKey = p.replace(removeStr, '').split('=')[0];
    oVal = p.replace(removeStr, '').split('=')[1];
    obj[oKey] = oVal;
  });
  return obj;
}

/** Simple Function to remove the Hash form the visible URL in the Browser */
function removeHashFromUrl(): string {
  try {
    ('replaceState' in window.history) ?
      window.history.replaceState('', document.title, window.location.pathname + window.location.search) :
      window.location.hash = '';
    return window.location.href;
  } catch (e) {
    return window.location.href;
  }
}

/** Decodes HTML */
function decodeHtml(src: string): string {
  return src
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace('/$quot;/g', '"');
}

export {
  unifyUrl,
  getParameter,
  separateParameters,
  removeHashFromUrl,
  decodeHtml
};
